import { useEffect } from 'react';

export const siteName = 'Halles Trottemant';
export const separator = ' - ';

/**
 * Modifie le **document.title** avec le nom du site
 * @param title Si **null**, indique juste nom du site
 */
export function useTitle(title: string | null) {
	useEffect(() => {
		document.title = title ? title + separator + siteName : siteName;
	});
}
