import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import Button from '../../Button/Button';
import DoneMessage from '../../Form/DoneMessage/DoneMessage';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import SmallLink from '../../Form/SmallLink/SmallLink';
const mutation = loader('./askForNewClientPassword.graphql');

export function _MotDePasseOublie({ location }: RouteComponentProps) {
	useTitle('Mot de passe oublié');

	const searchParams = new URLSearchParams(location.search);

	const [code, setCode] = useState(searchParams.get('code') || '');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState('');

	async function onSubmit() {
		setLoading(true);
		try {
			await request(mutation, { clientCode: code });
			setDone(true);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return !done ? (
		<Form onSubmit={onSubmit}>
			<Input
				label="Votre code"
				value={code}
				setValue={(val: string) => setCode(val.toUpperCase())}
				required
				autoFocus
			/>

			<SmallLink to={'/connexion' + (code ? '?code=' + code : '')}>
				Retour à l'écran de connexion
			</SmallLink>

			<ErrorMessage>{error}</ErrorMessage>

			<Button submit loading={loading} disabled={!code}>
				Envoyer
			</Button>
		</Form>
	) : (
			<DoneMessage link={{ to: '/connexion', text: 'Se connecter' }}>
				Un code avec un lien temporaire pour changer votre mot de passe vous a été
				envoyé. Vérifiez votre boîte de réception ainsi que vos spams.
			</DoneMessage>
		);
}

export default withRouter(_MotDePasseOublie);
