import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'destyle.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import './styles/outdated.scss';
import './styles/style.scss';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers';

// Setting CreateRoot After upgrade React 16 to React 18 
// const container = document.getElementById('root') as Element
// const root = createRoot(container)

const store = configureStore();

const render = (Component: any) => {
	return ReactDOM.render(
		<Provider store={store}>
			<BrowserRouter>
				<Component />
			</BrowserRouter>
		</Provider>,
		document.getElementById('root'),
	);
};

render(App);

// Hot Module Reloading
if ((module as any).hot) {
	(module as any).hot.accept('./components/App/App', () => render(App));
}

// Service Worker
serviceWorker.register({
	// onSuccess: () => {
	// 	console.log(`Le serviceWorker a bien été installé`);
	// },
	onUpdate: () => {
		console.info(
			`Une mise à jour est disponible pour cette application. Veuillez fermer et rouvrir cet onglet pour avoir les dernières modifications.`,
		);
	},
});
